<template>
	<div :class="`filter filter-${type}`">
		<div>
			<FilterFieldDiet
				v-if="type === 'side'"
				:clickaway="clickaway"
			/>
			<FilterFieldPeriod :clickaway="clickaway" />
			<FilterFieldTaxon :clickaway="clickaway" />
			<FilterFieldMedia :clickaway="clickaway" />
		</div>
	</div>
</template>

<script>
export default {
	components: {
		FilterFieldDiet: () => import(/* webpackChunkName: "FilterFieldDiet" */ '@/components/filter/FilterFieldDiet'),
		FilterFieldMedia: () => import(/* webpackChunkName: "FilterFieldMedia" */ '@/components/filter/FilterFieldMedia'),
		FilterFieldPeriod: () => import(/* webpackChunkName: "FilterFieldPeriod" */ '@/components/filter/FilterFieldPeriod'),
		FilterFieldTaxon: () => import(/* webpackChunkName: "FilterFieldTaxon" */ '@/components/filter/FilterFieldTaxon')
	},
	props: {
		type: {
			type: String,
			default: 'menu',
			validation: (value) => ['side', 'menu'].includes(value)
		}
	},
	data() {
		return {
			keylog: [],
			keypressTimer: 0,
			keypressEvent: false
		};
	},
	computed: {
		clickaway() {
			return (this.type === 'menu');
		}
	}
};
</script>

<style lang="scss">
	.filter {
		&-side {
			.filter-field {
				padding: 1rem 0;
			}

			.dropdown-toggle {
				padding: 0;
				background-color: inherit !important;
				border-radius: 0;
				font-size: inherit;
			}

			.dropdown-menu {
				overflow: visible;
				transform: none;
				position: static;
				padding: 0;
				max-height: none;
				background: inherit;
				border: 0;
				border-radius: 0;
				box-shadow: none;

			}
		}

		&-menu {
			margin: 3rem auto 1rem;
			max-width: $max-width;

			> div {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				position: relative;
				margin: 0 -0.5rem;
			}

			.dropdown {
				flex: 0 0 calc(25%  - 1rem);
				margin: 0.5rem;
				min-width: 200px;
			}
		}
	}
</style>
